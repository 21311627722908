import * as React from "react";
import SEO from "../components/SEO";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/investment_cover.jpg";
import TunisiaJobs15 from "../assets/img/investment_1.jpg";
import TunisiaJobs16 from "../assets/img/ProjectFund3.png";
import TunisiaJobs17 from "../assets/img/investment_3.jpg";
import Arrow from "../themes/custom/jobs_front/assets/img/arrowRight2.png";

const Investment = () => {
  return (
    <>
      <SEO title="Investment | Visit Tunisia Project" />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Investment and Innovation
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Providing innovative financial solutions
                        <span> in the tourism industry.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                about="/en/Assistance_To_Enterprises"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="sector-vision">
                        <div className="container">
                          <div className="row">
                            <div className="card-wrapper">
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                              >
                                <div className="inner-card">
                                  <h3>THE SECTOR</h3>
                                  <p>
                                    The COVID-19 pandemic has taken a severe
                                    toll on global travel and tourism. To
                                    weather the crisis and grow stronger,
                                    Tunisia’s tourism industry needs robust
                                    private sector investment and new, creative
                                    business models that capitalize on the
                                    country’s full tourism potential.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay
                              >
                                <div className="inner-card">
                                  <h3>VISIT TUNISIA’S VISION AND STRATEGY</h3>
                                  <p>
                                    The USAID Visit Tunisia Activity facilitates
                                    investment in Tunisia’s tourism industry
                                    through tailored grant programs, technical
                                    assistance, and infrastructure support to
                                    select tourism sites in target destinations.
                                    The project also works to facilitate strong
                                    public-private partnerships that enhance
                                    public tourism sites and strengthen the
                                    industry’s competitiveness. Ultimately, we
                                    aim to see promising tourism providers
                                    receive the backing they need to recover,
                                    grow, and thrive.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="resources" id="grants">
                        <div className="container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              Our Work
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div
                              className="d-flex flex-md-row flex-column"
                              style={{ marginBottom: "25px" }}
                            >
                              <div className="left-side">
                                <p>
                                  Visit Tunisia provides support to facilitate
                                  investment, increase access to finance, and
                                  improve infrastructure at select tourism sites
                                  through three key funds: a grants fund,
                                  project fund, and infrastructure fund.
                                </p>
                              </div>
                              <div className="right-side d-flex justify-content-md-end">
                                <div className="ticket">
                                  <h3>Where We Work</h3>
                                  <a
                                    className="underlined-link"
                                    href="/where-we-work"
                                    target="_blank"
                                  >
                                    Learn More{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grants-section">
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate our-work-image"
                                style={{ position: "relative" }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs15}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Grants Fund</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Supports hard-hit MSMEs in the tourism
                                      sector to develop unique, sustainable
                                      products, services, and experiences to
                                      meet demand, including through
                                      partnerships with microfinance
                                      institutions (MFIs) to increase MSMEs’
                                      access to finance.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={100}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs16}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Project Fund</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Leverages and catalyzes private capital
                                      investments and innovative partnerships in
                                      the tourism industry, including through
                                      the use of technology and digital
                                      innovation.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs17}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Infrastructure Fund</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      Funds the development and renovation of
                                      tourism sites and facilities in target
                                      geographic areas.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <section
                        className="impact aos-animate"
                        data-aos="fade-up"
                      >
                        <div className="brick brick--type--impact brick--id--54 eck-entity container">
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              OUR GOALS
                            </div>
                          </span>
                          <h2></h2>
                          <ul className="pl-0">
                            <li>
                              <span>
                                Increase access to finance for more than 2,000
                                Tunisian businesses and entrepreneurs in the
                                sustainable tourism sector
                              </span>
                            </li>
                            <li>
                              <span>
                                Mobilize more than $20 million in commercial
                                credit and investment for the sustainable
                                tourism sector
                              </span>
                            </li>
                            <li>
                              <span>
                                Support and promote innovations in tourism
                                through digital platforms, mobile applications
                                and virtual reality technology
                              </span>
                            </li>
                          </ul>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default Investment;
